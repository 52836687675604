import React from "react";
import Flexbox from "flexbox-react";

import Page from "../components/Layout/Page";
import { FixedWidthContainer } from "../components/Layout/ContentContainers";

import { COLORS } from "../utils/theme";

class Style extends React.Component {
  render() {
    const SECTION_HEADERS = {
      textAlign: "center",
      margin: "3.11rem 0 1.5rem"
    };
    const HEADLINE_MIN_WIDTH = "60%";
    const FLEXBOX_MARGIN = "20px";

    return (
      <Page
        title="The FEST 18 Style Guide"
        noSponsorsTierOne
        noSponsorsTierTwo
        noSponsorsTierThree
      >
        <FixedWidthContainer>
          <h2 style={SECTION_HEADERS}>Colors</h2>

          {Object.keys(COLORS).map(color => {
            const colorInfo = COLORS[color];

            return (
              <Flexbox flexDirection="row" alignItems="stretch" key={color}>
                <Flexbox marginRight={FLEXBOX_MARGIN}>
                  <div
                    style={{
                      border: "1px solid #151717",
                      height: 24,
                      width: 24,
                      background: colorInfo.hex
                    }}
                  />
                </Flexbox>
                <Flexbox width={"60px"} marginRight={FLEXBOX_MARGIN}>
                  <p>{color}</p>
                </Flexbox>
                <Flexbox width={"80px"} marginRight={FLEXBOX_MARGIN}>
                  <p>{colorInfo.hex}</p>
                </Flexbox>
                <Flexbox>
                  <p>{colorInfo.rgba}</p>
                </Flexbox>
              </Flexbox>
            );
          })}

          <h2 style={SECTION_HEADERS}>Fonts</h2>
          <h3 style={{ textTransform: "uppercase" }}>Rubik</h3>
          <p>Use me for headlines. Always use me in UPPERCASE.</p>
          <p>
            Download me here:{` `}
            <a
              href="https://fonts.google.com/specimen/Rubik"
              rel="nofollow"
            >
              https://fonts.google.com/specimen/Rubik
            </a>
          </p>
          <br />
          <h3
            style={{
              fontFamily: "Roboto",
              fontWeight: 400,
              textTransform: "uppercase"
            }}
          >
            Roboto
          </h3>
          <p>
            Use me for body text and lists. Falls back to "San Francisco" or
            "Helvetica" on MacOS, "Segoe UI" or "Arial" on Windows, and "Ubuntu"
            on Linux.
          </p>
          <p>
            Download me here:
            {` `}
            <a href="https://fonts.google.com/specimen/Roboto" rel="nofollow">
              https://fonts.google.com/specimen/Roboto
            </a>
          </p>

          {/* <p>
            Please put ALL chunks of text going onto the website in paragraph
            tags:
            <pre>{`<p>hi</p>`}</pre>
            Ask Steve if you don't know what this means.
          </p>
          */}

          <h2 style={SECTION_HEADERS}>Typography</h2>

          <Flexbox flexDirection="row" alignItems="baseline">
            <Flexbox minWidth={HEADLINE_MIN_WIDTH} marginRight={FLEXBOX_MARGIN}>
              <h1>This is an h1.</h1>
            </Flexbox>
            <Flexbox marginRight={FLEXBOX_MARGIN}>
              <p>44px on desktop</p>
            </Flexbox>
            <Flexbox>
              <p>35px on mobile</p>
            </Flexbox>
          </Flexbox>

          <Flexbox flexDirection="row" alignItems="baseline">
            <Flexbox minWidth={HEADLINE_MIN_WIDTH} marginRight={FLEXBOX_MARGIN}>
              <h2>This is an h2.</h2>
            </Flexbox>
            <Flexbox marginRight={FLEXBOX_MARGIN}>
              <p>38px on desktop</p>
            </Flexbox>
            <Flexbox>
              <p>29px on mobile</p>
            </Flexbox>
          </Flexbox>

          <Flexbox flexDirection="row" alignItems="baseline">
            <Flexbox minWidth={HEADLINE_MIN_WIDTH} marginRight={FLEXBOX_MARGIN}>
              <h3>This is an h3.</h3>
            </Flexbox>
            <Flexbox marginRight={FLEXBOX_MARGIN}>
              <p>26px on desktop</p>
            </Flexbox>
            <Flexbox>
              <p>25px on mobile</p>
            </Flexbox>
          </Flexbox>

          <Flexbox flexDirection="row" alignItems="baseline">
            <Flexbox minWidth={HEADLINE_MIN_WIDTH} marginRight={FLEXBOX_MARGIN}>
              <h4>This is an h4.</h4>
            </Flexbox>
            <Flexbox marginRight={FLEXBOX_MARGIN}>
              <p>22px on desktop</p>
            </Flexbox>
            <Flexbox>
              <p>21px on mobile</p>
            </Flexbox>
          </Flexbox>

          <Flexbox flexDirection="row" alignItems="baseline">
            <Flexbox minWidth={HEADLINE_MIN_WIDTH} marginRight={FLEXBOX_MARGIN}>
              <h5>This is an h5.</h5>
            </Flexbox>
            <Flexbox marginRight={FLEXBOX_MARGIN}>
              <p>18px on desktop</p>
            </Flexbox>
            <Flexbox>
              <p>18px on mobile</p>
            </Flexbox>
          </Flexbox>

          <Flexbox flexDirection="row" alignItems="baseline">
            <Flexbox minWidth={HEADLINE_MIN_WIDTH} marginRight={FLEXBOX_MARGIN}>
              <h6>This is an h6.</h6>
            </Flexbox>
            <Flexbox marginRight={FLEXBOX_MARGIN}>
              <p>16px on desktop</p>
            </Flexbox>
            <Flexbox>
              <p>16px on mobile</p>
            </Flexbox>
          </Flexbox>

          <br />
          <p>This is a short paragraph. The font size is 15px for body copy.</p>
          <p>
            This is a longer paragraph. THE FEST is an independent multiple-day,
            multiple-venue underground music festival held annually in
            Gainesville, Florida, with support from the fine folks at the City
            of Gainesville.
          </p>
          <p>
            This is another longer paragraph. THE FEST is an independent
            multiple-day, multiple-venue underground music festival held
            annually in Gainesville, Florida, with support from the fine folks
            at the City of Gainesville. This year, THE FEST celebrates 17 years
            in Gainesville and its sixth year in the Tampa Bay Area. With FEST
            growing too large for Gainesville to hold — and the influx of
            attendees from all over the world coming to town a few days early —
            five years ago, FEST expanded to include two lead up two days in
            Tampa, Florida (Historic Ybor City) with the launch of BIG PRE-FEST
            IN LITTLE YBOR!
          </p>
        </FixedWidthContainer>
      </Page>
    );
  }
}

export default Style;
